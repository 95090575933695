const createScrollFinishPromise = (element, wait): Promise<void> => {
    return new Promise((resolve) => {
        let timeout;
        const scrollListener = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                element.removeEventListener('scroll', scrollListener);
                resolve();
            }, wait);
        };
        timeout = setTimeout(() => {
            element.removeEventListener('scroll', scrollListener);
            resolve();
        }, wait);
        element.addEventListener('scroll', scrollListener);
    });
}


/**
 * Returns a promise which resolves when scrolling has finished.
 */
export const scrollToTop = (): Promise<void> => {
    const scrollRoot = document.getElementById('scroll-root');
    scrollRoot.scrollTop = 0;
    return createScrollFinishPromise(scrollRoot, 100)
}
