import registerComponent from "../register";
import {ContextBinding} from '@ornery/web-components';
import path from "path";
import renderMiniToc from "./mini-toc.html";

/**
 A mini TOC component which will render the basic structure starting from a given path as simple UL/href tree.

 ### HTML Usage
 ```html
 <mini-toc href="/some/path/"></mini-toc>
 ```
 ### React Component
 ```jsx
 <mini-toc href="/some/path/" />
 ```

 ### CSS default values
 ```css
 :root {
    --miniTocThemePrimary: inherit;
    --miniTocThemeOnPrimary: inherit;
    --miniTocListStyle: disc;
    --miniTocUlMargin: 0 0 0 1em;
    --miniTocUlPadding: 1em 0;
    --miniTocLiMargin: 0;
}
 ```

 * @module MiniToc
 * @extends {HTMLElement}
 * @element mini-toc
 *
 * @description A mini TOC component which will render the basic structure starting from a given path as simple UL/href tree.
 *
 * @attr {String} [href=""] - the path to start rendering a strcture tree from.

 * @cssprop --miniTocThemePrimary - Overrides the mwc-icon-button font-size.
 * @cssprop --miniTocThemeOnPrimary - Overrides the mwc-icon-button font-family.
 * @cssprop --miniTocListStyle - Overrides the --mdc-theme-primary css variable for this component.
 * @cssprop --miniTocUlPadding - Overrides the --mdc-theme-on-primary css variable for this component.
 * @cssprop --miniTocUlMargin - Overrides the color property for the mwc-button.
 */
export default class MiniToc extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({mode: "open"})
    }

    connectedCallback() {
        return this.render()
    }

    async fetchApiContent(contentPath) {
        return await fetch(path.join('/api/structure/', contentPath || "/"), {
            headers: {["Accept-Language"]: this.getAttribute("locale")
            }
        }).then(response => response.json()).catch((err) => console.log(err.message))
    }

    renderTree(node, depth = 1) {
        return node.children && node.children.map((n) => {
            return `<li><a href="/${n.href}">${n.title}</a>${n.children.length ? `<ul class="depth-${depth}">${this.renderTree(n, depth + 1)}</ul>` : ''}</li>`
        }).join('')
    }

    async render() {
        this.shadowRoot.innerHTML = '';
        const apiContent = await this.fetchApiContent(this.getAttribute('href'));
        renderMiniToc({
            tree: this.renderTree(apiContent)
        }).forEach((c) => this.shadowRoot.appendChild(c))
    }
}

registerComponent('mini-toc', ContextBinding(MiniToc))
