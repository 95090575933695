import ReactGA from "react-ga";
import Router from "next/router";
import NProgress from "nprogress";

NProgress.configure({
    trickleRate: 0.02,
    trickleSpeed: 800,
    container: "#scroll-root",
    showSpinner: true,
});

Router.ready(() => {
    Router.events.on("routeChangeStart", (a) => {
        if(Router.asPath !== a) {
            NProgress.start()
        }
    });
    Router.events.on("hashChangeStart", (a) => {
        if(Router.asPath !== a) {
            NProgress.start()
        }
    });

    Router.events.on("hashChangeComplete", () => {
        NProgress.done()
    });

    Router.events.on("routeChangeComplete", () => {
        NProgress.done()
        if (typeof window !== "undefined") {
            //@ts-ignore
            if (window.GAInitialized) {
                ReactGA.pageview(window.location.pathname + window.location.search);
            }
            const scrollRoot = document.getElementById("scroll-root");
            scrollRoot && typeof(scrollRoot.scrollTo) === "function" && scrollRoot.scrollTo({
                top: 0,
                left: 0
            });
        }
    });
});

Router.events.on("routeChangeError", () => {
    NProgress.done()
});

export default NProgress;
export {Router}