import React from "../components/register/react";
import App from "next/app";
import {StylesProvider, ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import initAnalytics from "utils/analytics";
import getCssContext from "@jorsek/content-portal-themes/bin/getCssContext";
import {createI18n, I18nProvider} from '../i18n';
import {featureDetection} from "utils/featureDetection";
import '../components/ProgressBar';
import '@ornery/render-html';
import '../components/locale-selector/locale-selector';
import '../components/version-selector/version-selector';
import '../components/type-ahead/type-ahead';
import '../components/print-content/print-content';
import '../components/mini-toc/mini-toc';
import '../components/copy-badge/copy-badge';
import '../components/zoomable-img/zoomable-img';
import '../components/code-format/code-format';
import '../components/i18n/i18n-message';
import importWebComponents from "components/import";
import Router from "next/router";
import HerettoDeployWindowObj, {ClientEvent} from "components/HerettoDeployWindowObj";


if (typeof window !== 'undefined') {
    featureDetection(window);
    (window as any).$Router = Router;
    HerettoDeployWindowObj.init(window);
}

interface IAppProps {
    contextProps: IContextProps;
    pageProps: any,
    globalProps: any,
    runtimeConfig: any
}

class _App extends App<IAppProps> {

    componentDidMount() {
        importWebComponents();
        const jssStyles = document.getElementById('jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
        const jssGlobalStyles = document.getElementById('jss-server-side-global');
        if (jssGlobalStyles) {
            jssGlobalStyles.parentElement.removeChild(jssGlobalStyles);
        }
        const {runtimeConfig = {}} = this.props.pageProps;
        initAnalytics(runtimeConfig);
        HerettoDeployWindowObj.triggerEvent(ClientEvent.initialPageLoad);
    }

    render() {
        //@ts-ignore
        const {Component, pageProps, jwt, ...globalProps} = this.props as any;
        const {runtimeConfig = {}} = this.props.pageProps;
        const locale = this.props.router?.locale;
        const i18nInstance = createI18n({locale, runtimeConfig});
        if (typeof window !== 'undefined') {
            (window as any).I18n = i18nInstance;
        }
        if (pageProps && pageProps.groups) {
            if (pageProps.groups.relatedResources && !pageProps.groups.relatedResources.length) {
                pageProps.groups.relatedResources = globalProps.groups.relatedResources
            }
            if (pageProps.groups.whatsNew && !pageProps.groups.whatsNew.length) {
                pageProps.groups.whatsNew = globalProps.groups.whatsNew
            }
            if (pageProps.groups.siteSections && !pageProps.groups.siteSections.length) {
                pageProps.groups.siteSections = globalProps.groups.siteSections
            }
            if (pageProps.groups.externalSites && !pageProps.groups.externalSites.length) {
                pageProps.groups.externalSites = globalProps.groups.externalSites
            }
        }
        const mergedProps = {...pageProps, ...globalProps};
        const groups = {...pageProps.groups, ...globalProps.groups};
        const layoutProps = {
            jwt,
            runtimeConfig,
            ...mergedProps,
            groups
        }
        const pageContext = getCssContext(runtimeConfig);
        return (
            <I18nProvider value={i18nInstance}>
                <ThemeProvider theme={pageContext.theme}>
                    <StylesProvider
                        injectFirst={runtimeConfig.JSSInjectFirst}
                        generateClassName={
                            pageContext.generateClassName
                        }
                        sheetsRegistry={pageContext.sheetsRegistry}
                        sheetsManager={pageContext.sheetsManager}
                    >
                        <CssBaseline/>
                        <Component layoutProps={layoutProps} runtimeConfig={runtimeConfig} {...mergedProps} />
                    </StylesProvider>
                </ThemeProvider>
            </I18nProvider>
        );
    }
}

export default _App;