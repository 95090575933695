import renderLocaleSelector from './locale-selector.html'
import registerComponent from "../register";
import {ContextBinding} from '@ornery/web-components';
import Router from "next/router";
import {template} from "@jorsek/content-portal-utils";
import DataManager from "../../utils/dataManager";

const localeItem = (item) => `<mwc-list-item
    class="locale-selector-menu-item ${item.id}"
    style="--order: ${item.order}"
    value="${item.id}"
    ${item.selected ? "selected activated" : ""}
    onclick="handleItemClick">${
    item.iconSrc ?
        `<img class="locale-selector-menu-item-icon" alt="${item.id}"
              src="${item.iconSrc}"/>`
        : ''}${item.label}</mwc-list-item>`;
/**
 A styled dropdown that uses the Material Web Components
 Button (mwc-button) and Menu (mwc-menu and mwc-list-item) elements.
 requires the DataManager package from @ornery/web-components and the
 Router from next/router.

 ### HTML Usage
 ```html
 <locale-selector data-items="[...]"></locale-selector>
 ```
 ### React Component
 ```jsx
 <locale-selector data-items={JSON.stringify([...props.items])} />
 ```

 ### CSS default values
 ```css
 :root {
    --localeSelectorButtonHorizontalPadding: 0.5rem;
    --localeSelectorButtonTextTransform: none;
    --localeSelectorMenuItemHeight: 2rem;
    --localeSelectorThemePrimary: inherit;
    --localeSelectorThemeOnPrimary: inherit;
    --localeSelectorThemePrimarySelected: #fff;
    --localeSelectorThemeOnPrimarySelected: #777;
    --localeSelectorButtonIconColor: inherit;
    --localeSelectorButtonColor: inherit;
    --localeSelectorButtonBackgroundColor: inherit;
    --localeSelectorButtonWhiteSpace: nowrap;
    --localeSelectorButtonMargin: 0.5rem;
    --localeSelectorButtonDisplayNolocales: none;
    --localeSelectorMenuColor: inherit;
    --localeSelectorMenuBackgroundColor: inherit; 
    --localeSelectorMenuWhiteSpace: nowrap;
}
 ```

 * @module PortallocaleSelector
 * @extends {HTMLElement} uses the Material Web Components Button (mwc-button) and Menu (mwc-menu and mwc-menu-item) elements.
 * @element locale-selector
 *
 * @description A dropdown list of all the locales available for a given site-section.
 *
 * @attr {Array<{INode}>} [data-items=null] - The array list of alternate locales for the current page..
 * Automatically provided to DataManager as part of the next.js runtime pageData as "pageData.locales".
 * @attr {Array<{INode}>} [items=null] - Alias for the data-items attribute.
 * @attr String [icon-src=https://assets.portal.heretto.com/flags/${id}.svg] - ES6 template syntax string for the icon location.
 * "id" is the ID of the selected item. Can be used for flags or other iconography
 *
 * @fires locale-selected
 *
 * @cssprop --localeSelectorButtonHorizontalPadding - Overrides the horizontal padding for the mwc-button.
 * @cssprop --localeSelectorButtonTextTransform - Overrides the text-transform property for the mwc-button.
 * @cssprop --localeSelectorMenuItemHeight - Overrides the mwc-list-item height.
 * @cssprop --localeSelectorThemePrimary - Overrides the --mdc-theme-primary css variable for this component.
 * @cssprop --localeSelectorThemeOnPrimary - Overrides the --mdc-theme-on-primary css variable for this component.
 * @cssprop --localeSelectorThemePrimarySelected - Overrides the --mdc-theme-primary css variable for mwc-list-item when selected
 * @cssprop --localeSelectorThemeOnPrimarySelected - Overrides the --mdc-theme-on-primary css variable for mwc-list-item when selected
 * @cssprop --localeSelectorButtonIconColor - Overrides the color property for the mwc-icon.
 * @cssprop --localeSelectorButtonColor - Overrides the color property for the mwc-button.
 * @cssprop --localeSelectorButtonBackgroundColor - Overrides the background-color property for the mwc-button.
 * @cssprop --localeSelectorButtonWhiteSpace - Overrides the white-space property for the mwc-button.
 * @cssprop --localeSelectorButtonMargin - Overrides the margin property for the mwc-button.
 * @cssprop --localeSelectorButtonDisplayNolocales - Overrides the display property for the mwc-button when there are no locales.
 * @cssprop --localeSelectorMenuColor - Overrides the color property for the mwc-menu
 * @cssprop --localeSelectorMenuBackgroundColor - Overrides the background-color property for the mwc-menu
 * @cssprop --localeSelectorMenuWhiteSpace - Overrides the white-space property for the mwc-menu
 * @cssprop --localeSelectorOrder - specifies the order of locales in the dropdown list. any omitted will be in the same order as the underlying data.
 * @example
 * ```css
 :root {
    --localeSelectorOrder: en,fr,ko;
}
 ```
 */
export default class PortalLocaleSelector extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({mode: 'open'})
    }
    static observedAttributes = ["locale"]

    private locales = [];
    private listener: any;

    connectedCallback() {
        Router.ready(() => {
            Router.events.on("hashChangeStart", this.checkUpdateLocale);
            Router.events.on("hashChangeComplete", this.checkUpdateLocale);
            Router.events.on("routeChangeStart", (targetPath) => {
                let sourcePath = window.location.href.replace(window.location.origin, "");
                let sourcePathArray = sourcePath.split("/");
                let targetPathArray = targetPath.split("/");
                if (sourcePathArray && sourcePathArray.length > 1 && targetPathArray && targetPathArray.length > 1) {
                    if (sourcePathArray[1] != targetPathArray[1]) {
                      window.location.href = window.location.origin + targetPath;
                    }
                }
                this.checkUpdateLocale();
            });
            Router.events.on("routeChangeComplete", this.checkUpdateLocale);
        });
        this.listener = DataManager.subscribe(() => {
            this.render()
        })
    }

    checkUpdateLocale = () => {
        this.render();
    }

    disconnectedCallback() {
        this.listener && this.listener.destroy();
        this.getButton().removeEventListener('click', this.openMenu);
        Router.events.off("hashChangeStart", this.checkUpdateLocale);
        Router.events.off("hashChangeComplete", this.checkUpdateLocale);
        Router.events.off("routeChangeStart", this.checkUpdateLocale);
        Router.events.off("routeChangeComplete", this.checkUpdateLocale);
    }

    attributeChangedCallback(){
        let attrLocale = this.getAttribute("locale");
        if (attrLocale && I18n?.getLocale() !== attrLocale) {
            I18n?.setLocale(attrLocale)
        }
    }
    
    get locale() {
        return this.getAttribute("locale");
    }

    get icon() {
        return this.getAttribute('icon') || "arrow_drop_down";
    }

    get variant() {
        return this.getAttribute('variant') || "raised";
    }

    get iconSrc() {
        return this.getAttribute('icon-src') || "https://assets.portal.heretto.com/flags/${id}.svg";
    }

    get localeOrder(): string {
        return getComputedStyle(document.documentElement).getPropertyValue('--localeSelectorOrder') || "";
    }

    get localeList(): string {
        const localeOrder = this.localeOrder;
        const items = this.locales.sort((a, b) => (I18n?.get(a) < I18n?.get(b) ? -1 : 1)) as any;
        return (items).map((item) => {
            const orderIndex = localeOrder.indexOf(item);
            const order = orderIndex === -1 ? 9999 : orderIndex;
            return localeItem({
                order,
                id: item,
                iconSrc: this.getIconSrc(item),
                selected: this.locale === item,
                label: I18n?.get(item)
            })
        }).join('')
    }

    getIconSrc(locale: string = '') {
        const code = this.getCountryCode(locale)
        return code && template(this.iconSrc, {id: code});
    }

    getCountryCode(item: string = '') {
        if (item && item.indexOf('-') > -1) {
            const countryParts = item.toLowerCase().split('-')
            return countryParts.length > 1 ? countryParts[countryParts.length - 1] : '';
        }
        return '';
    }

    setButtonText(value) {
        this.getSelectedIcon().setAttribute('src', this.getIconSrc(value))
        this.getButtonLabel().setAttribute('id', value);
        this.getButtonLabel().innerHTML = I18n?.get(value);
    }

    getMenu(): any {
        return this.shadowRoot.querySelector('.locale-selector-menu') || this;
    }

    getButton(): any {
        return this.shadowRoot.querySelector('.locale-selector-button') || this;
    }

    getButtonLabel() {
        return this.shadowRoot.querySelector('.locale-selector-menu-item--selected') || this;
    }

    getSelectedIcon() {
        return this.shadowRoot.querySelector('.locale-selector-menu-item-icon--selected') || this;
    }
    
    isRootPage(path: string): boolean {
      return path.split("/").length == 2;
    }

    handleItemClick(evt) {
        let nextLocale = evt.target.getAttribute('value');
        const pageData = DataManager.get('pageData') || {};
        
        this.dispatchEvent(new CustomEvent('locale-selected', {detail: nextLocale}))
        this.setButtonText(nextLocale)
        let asPath = Router.asPath;
        if (pageData.content?.alternateLanguages && pageData.content?.alternateLanguages[nextLocale]) {
            asPath = `/${pageData.content?.alternateLanguages[nextLocale].href}`
        } else if (pageData.content?.uuid) {
            try {
                return fetch(`/api/structure/?uuid=${pageData.content.uuid}&locale=${nextLocale}`)
                    .then((response) => response.json())
                    .then(({href}) => {
                        const asPath = `/${href}`;
                        Router.push(asPath, asPath, {
                            scroll: true,
                            locale: nextLocale,
                            shallow: false
                        }).then(() => {
                            I18n?.setLocale(nextLocale)
                        });
                    })
            } catch (ex) {
                console.log("unable to determine localized path", Router.asPath, nextLocale, ex.message)
            }
        }
        return Router.push(asPath, asPath, {
            scroll: true,
            locale: nextLocale,
            shallow: false
        }).then(() => {
            I18n?.setLocale(nextLocale)
        });
    }

    openMenu = () => {
        this.getMenu().open = true;
    }

    render() {
        const pageData = DataManager.get('pageData') || {};
        const {runtimeConfig = {}} = pageData || {};
        let locales = runtimeConfig.availableLocales || [runtimeConfig.defaultLocale || "en"]
        if (locales.length > 1) {
            this.style.display = "none";
            this.locales = locales;
            renderLocaleSelector(this).connect();
            const menu = this.getMenu();
            const button = this.getButton();
            menu.anchor = this;
            button.addEventListener('click', this.openMenu);
            this.setButtonText(Router.locale)
            menu.classList.remove('no-locales');
            button.classList.remove('no-locales');
            setTimeout(() => {
                this.style.display = null;
            }, 500)
        } else {
            this.getButton().classList.add('no-locales');
            this.getMenu().classList.add('no-locales');
        }
    }
}

registerComponent('locale-selector', ContextBinding(PortalLocaleSelector))
