const importWebComponents = ()=> {
    //@ts-ignore
    if (typeof window !== "undefined" && !window.document.documentMode) {
        //we are not IE
        import('@material/mwc-icon-button');
        import('@material/mwc-button');
        import('@material/mwc-menu');
        import('@material/mwc-list');
        import('@material/mwc-textfield');
        import('@material/mwc-icon');
    } 
}
export default importWebComponents;