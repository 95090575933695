
const register = (componentName, ctor, options = {}) =>{
    if (typeof window !== "undefined" && typeof window.customElements !== "undefined") {
        const existing = customElements.get(componentName);
        if (existing) {
            console.debug(`
**************************************************
${componentName} is already defined! This may be due to multiple imports or was overridden.
**************************************************
${componentName} was imported as ${existing.toString()}\n`,
                componentName, ctor, options)
        } else {
            customElements.define(componentName, ctor, options)
        }
    }
    
}
export default register;

export const upgradeWebComponents = (target:HTMLElement, outputClasses:any)=>{
    Object.entries(outputClasses).forEach(([selector, classesToAdd]:any) => {
        classesToAdd = Array.isArray(classesToAdd) ? classesToAdd: [classesToAdd];
        target.querySelectorAll(selector).forEach((e)=>{
            classesToAdd.forEach((oc) => {
                if (typeof oc === "string") {
                    e.classList.add(oc)
                } else if (typeof oc=== "object") {
                    Object.entries(oc).forEach(([k,v])=> e.setAttribute(k,v))
                }
            })
        })
    });
    const allElements = target.querySelectorAll("*");
    allElements.forEach((el) => {
        let isAttr = el.hasAttribute("is");
        let isType = isAttr ? el.getAttribute("is") : Array.from(el.classList).find((c) => c.startsWith("is-"))
        if (isType) {
            el.classList.remove(isType)
            const parent = el.parentNode;
            const wcType = isAttr ? isType : isType.replace('is-', '');
            const wcElement = document.createElement(isAttr? el.nodeName: wcType,isAttr? {is:isType}: null);
            Array.from(el.attributes).forEach((att) => {
                wcElement.setAttribute(att.nodeName, att.nodeValue);
            })
            parent.replaceChild(wcElement, el);
            wcElement.appendChild(el);
        }
    })
}