import {shouldEncode, encodeHTML, ContextBinding} from "@ornery/web-components";
import DataManager from "../../utils/dataManager";
import registerComponent from "components/register";

/**
 * @class I18nMessage
 * @description <i18n-message> HTML element. Provides tranlsation and interpolation for
 * translatable strings
 * @param {String} key the key for the strings based on current language. can be set as the innerHTML or
 * defined as the attibutes: key, id, data-key, data-id
 * @param {JSON} values can be passed as data-* attributes or as a json-parseable object string as "data-values"
 * @param {String} dataAttributes
 * @example <caption>Given the following configuration</caption>
 * import { I18n } from '@ornery/web-components';
 * I18n.addMessages('en-US', {
 *  'translatable.message.name': "I'm a translated string from i18n",
 *   'tokenized.message': "I have a ${color} ${animal}"
 * });
 * @example @lang html <caption>With the following usage</caption>
 * <i18n-message>translatable.message.name</i18n-message>
 * <div>
 *    <i18n-message data-values="{'color: 'grey', 'animal': 'monkey'}">tokenized.message</i18n-message>
 *    <i18n-message data-color="grey" data-animal="monkey">tokenized.message</i18n-message>
 *    <i18n-message key="tokenized.message"/>
 *    <!-- React does not pass key or ref props so you can use "data-key" or "data-id" as well-->
 *    <i18n-message data-key="tokenized.message"/>
 *    <i18n-message id="translatable.message.name"/>
 *    <i18n-message data-id="translatable.message.name"/>
 * </div>
 *
 * @example @lang html <caption>Renders the HTML</caption>
 * <i18n-message>I'm a translated string from i18n</i18n-message>
 * <i18n-message>I have a grey monkey</i18n-message>
 */
class I18nMessage extends HTMLElement {
    private _i18nListener: any;
    constructor() {
        super();
    }

    static get observedAttributes() {
        return ['key', 'id', 'data-values'];
    }

    get useShadow() {
        if (this.hasAttribute('shadow')) {
            const current = this.getAttribute('shadow');
            if (current === 'false') {
                return false;
            }
        }
        return true;
    }

    get t():string {
        return (this.getAttribute('key') || this.getAttribute('id')) as string;
    }

    attributeChangedCallback() {
        this.update();
    }

    update() {
        const value = this.getAttribute('data-text-value');
        if (value) {
            this.innerText = value
            return;
        }
        const root = this.shadowRoot || this;
        const context = {...(this.getAttribute('data-values') || {}) as any, ...this.dataset};
        let newMesage = I18n.get(this.t, context);
        if (shouldEncode(newMesage)) {
            newMesage = encodeHTML(newMesage);
        }
        if (!root.innerHTML) {
            root.innerHTML = newMesage;
        } else if (newMesage !== this.t || newMesage !== root.innerHTML) {
            root.innerHTML = newMesage;
        }
    }

    connectedCallback() {
        if (this.useShadow && !this.shadowRoot) this.attachShadow({mode: 'open'});
        this._i18nListener = DataManager.subscribe(() => {
            this.update();
        });
        const attrObserver = new MutationObserver(() => this.update());
        attrObserver.observe(this, {attributes: true, childList: this.useShadow});
    }

    disconnectedCallback() {
        if (this._i18nListener) {
            this._i18nListener.destroy();
            this._i18nListener = null;
        }
    }
}

registerComponent('i18n-message', ContextBinding(I18nMessage))