
export enum ClientEvent {
    pageUpdate = 'pageUpdate',
    initialPageLoad = 'initialPageLoad'
}

/**
 * This class allows a tenant's custom javascript to receive events from the portal.
 * This object is a singleton, and is placed on the window object, as window.__herettoDeploy.
 * 
 * A tenant's custom javascript can bind to portal events, as follows:
 *   window.addEventListener('DOMContentLoaded', () => {
 *     window.__herettoDeploy.on('contentPageUpdate', () => alert('hello'))
 *   })
 * This would fire the alert whenever the content page updates.
 * 
 */
export class HerettoDeployWindowObj {
    
    private _handlers: Map<ClientEvent, Array<(eventData?) => void>>;
    private _hasLoadedPage: boolean = false;

    constructor() {
        this._handlers = new Map();
        for (let clientEvent in ClientEvent) {
            this._handlers.set(ClientEvent[clientEvent], []);
        }
    }

    init(window: Window) {
        (window as any).__herettoDeploy = this
    }

    /**
     * Registers a handler to listen to event.
     * Specific handling for initial page load: if a handler of the initialPageLoad event
     * is registered after initial page load, we call it immediately.
     */
    on(eventName: string, handler: (eventData?) => void) {
        let event = ClientEvent[eventName]
        if (this._handlers.has(event)) {
            this._handlers.get(event).push(handler)
            if (event === ClientEvent.initialPageLoad && this._hasLoadedPage) {
                HerettoDeployWindowObj.notifyHandler(handler, undefined);
            }
            return true
        } else {
            console.log(`Tried to bind handler, but this event name does not exist:  ${eventName}`)
            return false;
        }
    }
    
    private static notifyHandler(handler: (eventData?) => void, eventData?: any) {
        try {
            handler(eventData)
        } catch (e) {
            console.log(e)
        }
    }
    
    triggerEvent(clientEvent: ClientEvent, eventData?: any) {
        try {
            if (clientEvent === ClientEvent.initialPageLoad) {
                this._hasLoadedPage = true
            }
            const handlers = this._handlers.get(clientEvent)
            if (handlers) {
                for (let handler of handlers) {
                    HerettoDeployWindowObj.notifyHandler(handler, eventData)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
}

const INSTANCE: HerettoDeployWindowObj = new HerettoDeployWindowObj() 
export default INSTANCE