import renderTypeAhead from './type-ahead.html'
import resultItem from './type-ahead-item.html'
import registerComponent from "../register";
import {ContextBinding} from '@ornery/web-components';
import Router from "next/router";
import {toSearch} from "@jorsek/content-portal-utils";

/**
 A styled dropdown that uses the Material Web Components
 Textfield (mwc-textfield) element. https://www.npmjs.com/package/@material/mwc-textfield

 ### CSS default values
 ```css
 :root {

--typeAheadWrapperDisplay: flex
--typeAheadWrapperHeight: 3rem
--typeAheadWrapperWidth: 400px
--typeAheadWrapperColor: inherit
--typeAheadWrapperBackgroundColor: inherit
--typeAheadWrapperMargin: 0
--typeAheadWrapperBoxShadow: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)
--typeAheadWrapperBorderRadius: 0.25rem
--typeAheadTextfieldFlex: 1
--typeAheadMenuColor: inherit
--typeAheadMenuBackgroundColor: inherit
--typeAheadMenuWhiteSpace: nowrap
--typeAheadMenuItemHeight: 56px
--typeAheadMenuItemColor: inherit
--typeAheadMenuItemBackgroundColor: inherit
--typeAheadMenuItemMaxWidth: 400px
--typeAheadMenuItemTitleFontSize: 16px
--typeAheadMenuItemTitleMargin: 0
--typeAheadMenuItemWhiteSpace: nowrap
--typeAheadMenuItemOverflow: hidden
--typeAheadMenuItemTextOverflow: ellipsis
--typeAheadMenuItemDescriptionFontSize: 12px
--typeAheadMenuItemDescriptionMargin: 0
--typeAheadMenuItemWhiteSpace: nowrap
--typeAheadMenuItemOverflow: hidden
--typeAheadMenuItemTextOverflow: ellipsis
--typeAheadIconCursor: pointer
--typeAheadIconColor: #4f4e4c
--typeAheadIconDisplay: block
--typeAheadIconAlign: center
--typeAheadIconMargin: 0 1rem 0 0
--typeAheadMenuItemPointerEvents: none
 ```
 
 * @module PortalTypeAhead
 * @extends {HTMLElement} uses the Material Web Components Textfield (mwc-textfield) element.
 * @element type-ahead
 *
 * @description A textfield-input menu list of all the results available for a given search query.
 *
 */

const handleRoutePush = (href) => {
    if (href) {
        if (!href.startsWith('/')) href = `/${href}`;
        Router.push(href, href, {scroll: true, shallow: false});
    }
}

export default class PortalTypeAhead extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({mode: 'open'})
    }

    private results = [];
    private fetching = null;
    private waiting = null;
    private menuIndex = -1;

    static get observedAttributes() {
        return [];
    }

    attributeChangedCallback(name) {
        if (name !== 'value') {
            this.render();
        }
    }

    connectedCallback() {
        this.render();
        this.setText(this.getAttribute("value"));
    }

    getTextfield() {
        return this.shadowRoot.querySelector('.type-ahead-textfield');
    }

    getMenu() {
        return this.shadowRoot.querySelector('.type-ahead-menu');
    }

    getSelectedItem() {
        return this.shadowRoot.querySelector('.type-ahead-menu-item[selected]');
    }

    getInput() {
        return (this.getTextfield() as any).formElement;
    }

    handleItemClick(evt) {
        const href = evt.target.getAttribute('href');
        handleRoutePush(href);
    }

    get icon() {
        return this.getAttribute('icon') || "search";
    }

    get placeholder() {
        return this.getAttribute('placeholder') || 'Search';
    }

    get label() {
        return this.getAttribute('label') || '';
    }

    get value() {
        return this.getAttribute('value') || '';
    }

    get searchsuggestions() {
        return this.hasAttribute("searchsuggestions") && this.getAttribute("searchsuggestions") !== "false"
    }

    get xoffset() {
        return this.getAttribute('xoffset') || '24';
    }

    get yoffset() {
        return this.getAttribute('yoffset') || '48';
    }

    get maxentries() {
        return parseInt(this.getAttribute('maxentries')) || 10;
    }

    get resultList(): string {
        return (this.results).map((link) => resultItem({
            ...link
        })).join('')
    }

    get searchHref() {
        return `/search${toSearch({
            queryString: (this.value?.trim() || "*"),
            page: 1,
        })}`
    }

    setText(value) {
        this.menuIndex = -1;
        if (value) this.getTextfield().setAttribute('value', value);
    }

    goToSearch() {
        Router.push(this.searchHref, this.searchHref, {scroll: true, shallow: false});
    }

    handleOnKeyUp(event) {
        if (event.target) {
            let menu = this.getMenu() as any;
            if (event.code === "Enter") {
                if (this.getSelectedItem()) {
                    const href = this.getSelectedItem().getAttribute('href');
                    handleRoutePush(href);
                } else {
                    this.goToSearch();
                }
            } else if (event.code === "ArrowUp" || event.code === "ArrowDown") {
                if (menu.open) {
                    if (event.code === "ArrowUp" && this.menuIndex > -1) this.menuIndex--;
                    if (event.code === "ArrowDown" && this.menuIndex < this.maxentries) this.menuIndex++;
                    menu.select(this.menuIndex);
                }
            } else {
                this.setAttribute('value', event.target.value);
                if (this.searchsuggestions) {
                    if (!this.fetching) {
                        this.fetching = this.fetchApiSearch().then(() => {
                            this.fetching = this.waiting ? this.handleOnKeyUp(this.waiting) : null;
                        }).catch((err) => console.log(err.message));
                    } else {
                        this.waiting = event.target.value;
                    }
                }
            }
        }
    }

    async fetchApiSearch() {
        if (this.value.length > 2) {
            this.results = await fetch(`/api/lookup/${toSearch({
                queryString: this.value,
                locale: this.getAttribute("locale")
            })}`)
                .then(response => response.json())
                .catch((err) => console.log(err.message))
        } else {
            this.results = [];
        }

        let menu = this.getMenu() as any;

        menu.innerHTML = "";
        this.results.forEach((result) => {
            resultItem({...result, handleItemClick: this.handleItemClick}).forEach((item) => {
                menu.appendChild(item);
            });
        });
        menu.open = !!this.results.length;
        const input = this.getInput();
        input && input.focus();
    }

    render() {
        renderTypeAhead(this).connect();
        let menu = this.getMenu() as any;
        menu.anchor = this.getTextfield();
        menu.defaultFocus = "NONE";
    }
}

registerComponent('type-ahead', ContextBinding(PortalTypeAhead))
