import renderZoomableImg from './zoomable-img.html'
import registerComponent from "../register";
import {ContextBinding} from '@ornery/web-components';

/**
 An image component that provides a zoomable, full-size modal of the image, 
 and a scaled down version that fits the parent containers.

 ### HTML Usage
 ```html
 <zoomable-img src="hosted.images.com/my-image.png"></zoomable-img>
 ```
 
 ### React Component
 ```jsx
 <zoomable-img src={"hosted.images.com/my-image.png"} />
 ```
 
 ### CSS default values
 ```css
 :root {

}
 ```

 * @module PortalZoomableImg
 * @extends {HTMLElement} uses the Material Web Components Icon (mwc-icon) element and slots.
 * @element zoomable-img
 *
 * @description An image component that provides a zoomable, full-size modal of the image, 
 * and a scaled down version that fits the parent containers.
 *
 * @attr {Url|String} [data-src=null] - The source image to use for display.
 */
export default class PortalZoomableImg extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({ mode: 'open' })
    }

    connectedCallback() {        
        this.render()
        this.addEventListener('gestureend',  this.detectPinch);
        this.addEventListener('mousewheel',  this.handleMouseWheel);
    }

    disconnectedCallback() {
        this.removeEventListener('gestureend',  this.detectPinch);
        this.removeEventListener('mousewheel',  this.handleMouseWheel);
    }
    
    handleMouseWheel = (e:any)=> {
        if (e.ctrlKey) {
            this.detectPinch(e)
        }
    }
    detectPinch = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.deltaY > 0 || e.scale < 1.0) {
            // User moved fingers closer together
            this.close()
        } else if (e.deltaY < 0 || e.scale > 1.0) {
            // User moved fingers further apart
            this.open()
        }
    }
    
    get $img () {
        return this.querySelector('img') || this.shadowRoot.querySelector('img');
    }
    get $wrapper () {
        return this.shadowRoot.querySelector('.zoomable-img-wrapper');
    }
    
    open = ()=>{
        this.$wrapper.classList.add("open");
        this.$img.style.height = (this.$img.naturalHeight + "px");
        this.$img.style.width = (this.$img.naturalWidth + "px");
    }

    close = ()=> {
        this.$wrapper.classList.remove("open");
        this.$img.style.height = null;
        this.$img.style.width = null;
    }
    
    handleMouseEnter = ()=>{
        if (this.imgIsConstrained) {
            this.$wrapper.classList.add('constrained')
        }
    }
    
    handleMouseLeave = ()=>{
        this.$wrapper.classList.remove('constrained')
    }
    
    get src(){
        const containedImg = this.$img || this;
        return containedImg.getAttribute("src");
    }
    
    get imgIsConstrained(){
        const containedImg = this.$img;
        return containedImg.naturalHeight > containedImg.clientHeight || containedImg.naturalWidth > containedImg.clientWidth;
    }

    render(){
        renderZoomableImg(this).connect();
    }
}

registerComponent('zoomable-img', ContextBinding(PortalZoomableImg))
