import registerComponent from "../register";
import {ContextBinding} from '@ornery/web-components';
import renderBadge from "./copy-badge.html";
/**
 A Copy Badge component which will copy contents of a container to the clipboard.

 ### HTML Usage
 ```html
 <copy-badge title="Copy To Clipboard"></copy-badge>
 ```
 ### React Component
 ```jsx
 <copy-badge href="/some/path/" />
 ```

 ### CSS default values
 ```css
 :host {
    position: var(--copyBadgePosition, absolute);
    right: var(--copyBadgeRight, 0);
    top: var(--copyBadgeTop, 0);
    --mdc-theme-primary: var(--copyBadgeThemePrimary, inherit);
    --mdc-theme-on-primary: var(--copyBadgeThemeOnPrimary, inherit);
}

 @media print {
    :host {
        display: none;
    }
}

 .code-badge {
    display: var(--copyBadgeDisplay, flex);
    flex-direction: var(--codeBadgeFlexDirection, row);
    white-space: var(--codeBadgeWhiteSpace, normal);
    background: var(--codeBadgeBackground, #999);
    color: var(--codeBadgeColor, white);
    font-size: var(--codeBadgeFontSize, 0.875em);
    opacity: var(--codeBadgeOpacity, 0.5);
    transition: var(--codeBadgeTransition, all 0.5s);
    border-radius: var(--codeBadgeBorderRadius, 0 0 0 7px);
    padding: var(--codeBadgePadding, 5px 8px 5px 8px);
}

 .code-badge-copy-icon {
    background: var(--copyBadgeIcon, url('data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJjb3B5IiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY29weSBmYS13LTE0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTQzMy45NDEgNjUuOTQxbC01MS44ODItNTEuODgyQTQ4IDQ4IDAgMCAwIDM0OC4xMTggMEgxNzZjLTI2LjUxIDAtNDggMjEuNDktNDggNDh2NDhINDhjLTI2LjUxIDAtNDggMjEuNDktNDggNDh2MzIwYzAgMjYuNTEgMjEuNDkgNDggNDggNDhoMjI0YzI2LjUxIDAgNDgtMjEuNDkgNDgtNDh2LTQ4aDgwYzI2LjUxIDAgNDgtMjEuNDkgNDgtNDhWOTkuODgyYTQ4IDQ4IDAgMCAwLTE0LjA1OS0zMy45NDF6TTI2NiA0NjRINTRhNiA2IDAgMCAxLTYtNlYxNTBhNiA2IDAgMCAxIDYtNmg3NHYyMjRjMCAyNi41MSAyMS40OSA0OCA0OCA0OGg5NnY0MmE2IDYgMCAwIDEtNiA2em0xMjgtOTZIMTgyYTYgNiAwIDAgMS02LTZWNTRhNiA2IDAgMCAxIDYtNmgxMDZ2ODhjMCAxMy4yNTUgMTAuNzQ1IDI0IDI0IDI0aDg4djIwMmE2IDYgMCAwIDEtNiA2em02LTI1NmgtNjRWNDhoOS42MzJjMS41OTEgMCAzLjExNy42MzIgNC4yNDMgMS43NTdsNDguMzY4IDQ4LjM2OGE2IDYgMCAwIDEgMS43NTcgNC4yNDNWMTEyeiI+PC9wYXRoPjwvc3ZnPg=='));
    background-size: var(--copyBadgeIconBackgroundSize, 100% 100%);
    font-size: var(--copyBadgeIconFontSize, 1.2em);
    cursor: var(--copyBadgeIconCursor, pointer);    
    padding: var(--copyBadgeIconPadding, 0 7px);
    margin: var(--copyBadgeIconMargin, 2px 0 0 0);
}

 .code-badge.copied .code-badge-copy-icon {
    background: var(--copyBadgeCopiedIcon, url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImNoZWNrIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2hlY2sgZmEtdy0xNiIgcm9sZT0iaW1nIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9IiYjMTA7ICAgIGNvbG9yOiAjMmFmZjMyOyYjMTA7Ij48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik0xNzMuODk4IDQzOS40MDRsLTE2Ni40LTE2Ni40Yy05Ljk5Ny05Ljk5Ny05Ljk5Ny0yNi4yMDYgMC0zNi4yMDRsMzYuMjAzLTM2LjIwNGM5Ljk5Ny05Ljk5OCAyNi4yMDctOS45OTggMzYuMjA0IDBMMTkyIDMxMi42OSA0MzIuMDk1IDcyLjU5NmM5Ljk5Ny05Ljk5NyAyNi4yMDctOS45OTcgMzYuMjA0IDBsMzYuMjAzIDM2LjIwNGM5Ljk5NyA5Ljk5NyA5Ljk5NyAyNi4yMDYgMCAzNi4yMDRsLTI5NC40IDI5NC40MDFjLTkuOTk4IDkuOTk3LTI2LjIwNyA5Ljk5Ny0zNi4yMDQtLjAwMXoiLz48L3N2Zz4='));
}

 .code-badge.active {
    opacity: var(--copyBadgeActiveOpacity, 0.8);
}

 .code-badge:hover {
    opacity: var(--copyBadgeHoverOpacity, 0.95);
}

 ```

 * @module CopyBadge
 * @extends {HTMLElement}
 * @element copy-badge
 *
 * @description A Copy Badge component which will render the basic structure starting from a given path as simple UL/href tree.
 *
 * @attr {String} [href=""] - the path to start rendering a strcture tree from.
 * 
 * @cssprop --copyBadgePosition - Overrides the icon position.
 * @cssprop --copyBadgeRight - Overrides the icon right position.
 * @cssprop --copyBadgeTop - Overrides the element top position.
 * @cssprop --copyBadgeThemePrimary - Overrides the --mdc-theme-primary css variable for this component.
 * @cssprop --copyBadgeThemeOnPrimary -  Overrides the --mdc-theme-on-primary css variable for this component.
 * @cssprop --copyBadgeIcon - Overrides the icon background image
 * @cssprop --copyBadgeIconBackgroundSize - Overrides the icon background size
 * @cssprop --copyBadgeFontSize - Overrides the icon font-size
 * @cssprop --copyBadgeCursor - Overrides the icon cursor
 * @cssprop --copyBadgePadding - Overrides the icon padding
 * @cssprop --copyBadgeMargin - Overrides the icon cursor
 * @cssprop --copyBadgeCopiedIcon - Overrides the clicked/copied state icon background image
 * @cssprop --copyBadgeActiveOpacity - Overrides the icon clicked/copied state opacity
 * @cssprop --copyBadgeHoverOpacity - Overrides the icon hover state opacity
 */
export default class CopyBadge extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({mode: "open"})
    }

    static get observedAttributes() {
        return ["title", "clipboard-text"]
    } 
    
    connectedCallback() {
        return this.render()
    }
    
    attributeChangedCallback() {
        return this.render()
    }
    
    get title() {
        return I18n?.get(this.getAttribute("title") || "messages.copy-clipboard");
    }   
    
    get successTimeout() {
        return parseInt(this.getAttribute("success-timeout") || "2", 10) * 1000;
    }

    async render() {
        this.shadowRoot.innerHTML = '';
        renderBadge({
            copyToClipboard: this.copyToClipboard,
            title: this.title
        }).forEach((c) => this.shadowRoot.appendChild(c))
    }

    copyToClipboard = (_e) => {
        const copyText = this.getAttribute('clipboard-text');
        if (copyText) {
            const el = document.createElement('textarea');
            el.value = copyText.trim();
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        } else {
            const selection = window.getSelection();
            selection.removeAllRanges();
            const range = document.createRange();
            range.selectNodeContents(this);
            selection.addRange(range);
            document.execCommand('copy');
        }
        this.dispatchEvent(new CustomEvent("copied", {detail: this }))
        const codeBadge = this.shadowRoot.querySelector(".code-badge");
        this.classList.add('copied')
        codeBadge.classList.add('copied')
        setTimeout(() => {
            this.classList.remove('copied')
            codeBadge.classList.remove('copied')
        }, this.successTimeout);
    }
}

registerComponent('copy-badge', ContextBinding(CopyBadge))
