import { ContextBinding } from "@ornery/web-components";
import register from "../register";
import copyBadgeBlock from "../../lib/highlightjs-badge";
import renderCodeFormat from './code-format.html';

/**
 A Code Format web component based on highlight.js 

 ### HTML Usage
 ```html
 <code-format></copy-badge>
 ```
 ### React Component
 ```jsx
 <code-format />
 ```
 * @module CodeFormat
 * @extends {HTMLElement}
 * @element code-format
 *
 * @cssprop --codeFormatWhitespace - default: `whitespace: pre-wrap`
 * @cssprop --codeFormatDisplay - default: `display: block`
 * @cssprop --codeFormatFontFamily - default: `font-family: monospace`
 * @cssprop --codeFormatPosition - default: `position: relative`
 * @cssprop --codeFormatMargin - default: `margin: 1em 0px`
 * @cssprop --codeFormatOverflowX - default: `overflow-x: auto`
 * @cssprop --codeFormatOverflowY - default: `overflow-y: initial`
 * @cssprop --codeFormatScrollBarWidth - default: `0.25rem`
 * @cssprop --codeFormatScrollBarHeight - default: `0.25rem`
 * @cssprop --codeFormatScrollBarBackground - default: `#fff`
 * @cssprop --hljs-ln-border-collapse - default: `collapse: collapse`
 * @cssprop --hljs-ln-td-padding - default: `padding: 0 0 0 0`
 * @cssprop --hljs-ln-numbers-padding - default: `padding: initial`
 * @cssprop --hljs-ln-numbers-margin - default: `margin: initial`
 * @cssprop --hljs-ln-numbers-display - default: `display: block`
 * @cssprop --hljs-ln-numbers-position - default: `position: static`
 * @cssprop --hljs-ln-numbers-color - default: `color: inherit`
 * @cssprop --hljs-ln-numbers-background - default: `background: inherit`
 * @cssprop --hljs-ln-numbers-vertical-align - default: `align: text-top`
 * @cssprop --hljs-ln-numbers-even-color - default: `color: var(--hljs-ln-numbers-color, inherit)`
 * @cssprop --hljs-ln-numbers-even-background - default: `background: var(--hljs-ln-numbers-background, inherit)`
 * @cssprop --hljs-ln-numbers-odd-color - default: `color: var(--hljs-ln-numbers-color, inherit)`
 * @cssprop --hljs-ln-numbers-odd-background - default: `background: var(--hljs-ln-numbers-background, inherit)`
 * @cssprop --hljs-table-padding - default: `padding: initial`
 * @cssprop --hljs-table-margin - default: `margin: initial`
 * @cssprop --hljs-table-display - default: `display: block`
 * @cssprop --hljs-table-position - default: `position: static`
 * @cssprop --hljs-table-color - default: `color: inherit`
 * @cssprop --hljs-table-background - default: `background: inherit`
 * @cssprop --hljs-padding - default: `padding: initial`
 * @cssprop --hljs-margin - default: `margin: initial`
 * @cssprop --hljs-display - default: `display: block`
 * @cssprop --hljs-position - default: `position: static`
 * @cssprop --hljs-background - default: `background: #fefefe`
 * @cssprop --hljs-color - default: `color: #545454`
 * @cssprop --hljs-comment-color - default: `color: #696969`
 * @cssprop --hljs-quote-color - default: `color: var(--hljs-comment-color, #696969)`
 * @cssprop --hljs-variable-color - default: `color: #d91e18`
 * @cssprop --hljs-template-variable-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-tag-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-name-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-selector-id-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-selector-class-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-selector-regexp-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-deletion-color - default: `color: var(--hljs-variable-color, #d91e18)`
 * @cssprop --hljs-literal-color - default: `color: #aa5d00`
 * @cssprop --hljs-number-color - default: `color: var(--hljs-literal-color, #aa5d00)`
 * @cssprop --hljs-built_in-color - default: `color: var(--hljs-literal-color, #aa5d00)`
 * @cssprop --hljs-type-color - default: `color: var(--hljs-literal-color, #aa5d00)`
 * @cssprop --hljs-params-color - default: `color: var(--hljs-literal-color, #aa5d00)`
 * @cssprop --hljs-meta-color - default: `color: var(--hljs-literal-color, #aa5d00)`
 * @cssprop --hljs-link-color - default: `color: var(--hljs-literal-color, #aa5d00)`
 * @cssprop --hljs-attribute-color - default: `color: #aa5d00`
 * @cssprop --hljs-symbol-color - default: `color: #008000`
 * @cssprop --hljs-string-color - default: `color: var(--hljs-symbol-color, #008000)`
 * @cssprop --hljs-symbol-color - default: `color: var(--hljs-symbol-color, #008000)`
 * @cssprop --hljs-symbol-color - default: `color: var(--hljs-symbol-color, #008000)`
 * @cssprop --hljs-title-color - default: `color: #007faa`
 * @cssprop --hljs-section-color - default: `color: var(--hljs-title-color, #007faa)`
 * @cssprop --hljs-keyword-color - default: `color: #7928a1`
 * @cssprop --hljs-selector-tag-color - default: `color: var(--hljs-keyword-color, #7928a1)`
 * @cssprop --hljs-emphasis-font-style - default: `style: italic`
 * @cssprop --hljs-strong-font-weight - default: `weight: bold`
 * @cssprop --hljs-hc-link-color - default: `color: highlight`
 * @cssprop --hljs-hc-keyword-font-weight - default: `weight: bold`
 * 
 */
export default class CodeFormat extends HTMLElement {
    constructor(){
        super();
        this.attachShadow({mode: 'open'})
    }
    
    attributeOrClass(val):boolean {
        return ((this.hasAttribute(val) && this.getAttribute(val) !== 'false')
            && !this.classList.contains("no" + val)) || this.classList.contains(val)
    }
    get themeCDN():string {
        const cdn = this.getAttribute('theme-cdn') || 'https://assets.portal.heretto.com/themes/hljs';
        return cdn.replace(/\/$/, '')
    }
    
    get themeSrc():string|null {
        return this.getAttribute('theme-src');
    }
    
    get theme() {
        let theme;
        const classTheme = Array.from(this.classList).find((c)=> c.startsWith('theme-'));
        if (classTheme) {
            theme = classTheme.replace('theme-', '')
        } else {
            theme = this.getAttribute("theme");
        }
        return (theme || 'a11y-light').replace(/\.css$/, '');
    }
    
    private getThemeLocation():string {
        return this.themeSrc || `${this.themeCDN}/${this.theme}.css`;
    }
    
    get codeLang ():string|null {
        return this.getAttribute('lang') || "sh"
    }    
    get lineNumbers ():boolean {
        return this.attributeOrClass("linenums")
    }    
    get lineNumberDecorator ():string|null {
        return this.getAttribute("linenums-decorator")
    }
    get copyable () {
        return this.attributeOrClass("copyable")
    }
    
    private callback = (target)=> {
        this.copyable && copyBadgeBlock(target, this);
        let hljsCSSElement = document.createElement('link');
        hljsCSSElement.setAttribute('rel', 'stylesheet');
        hljsCSSElement.setAttribute('href', this.getThemeLocation());
        this.shadowRoot.appendChild(hljsCSSElement);
        renderCodeFormat(this).forEach((c)=> this.shadowRoot.appendChild(c))
        const copyLink = this.shadowRoot.querySelector('copy-badge');
        copyLink && copyLink.setAttribute('clipboard-text', this.getAttribute('clipboard-text') || target.textContent)
    }
    
    connectedCallback() {
        import('../../lib/highlight').then((res:any)=>{
            const hljs = res.default;
            this.shadowRoot.innerHTML = this.innerHTML;
            const codeLang = "language-" + this.codeLang
            if (!this.classList.contains(codeLang)) {
                this.classList.add(codeLang);
            }

            hljs.highlightElement(this);
            if (this.lineNumbers) {
                let decorator = this.lineNumberDecorator || "";
                //@ts-ignore
                hljs.lineNumbersBlock(this, { decorator }, ()=> this.callback(this));
            } else {
                this.callback(this)
            }
        })
        
    }
}

register('code-format', ContextBinding(CodeFormat))