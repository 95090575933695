const React = require('react');
const {I18n} = require("@ornery/web-components");
const merge = require('deepmerge');
const customMerge = () => (a, b) => b == null ? a : merge(a, b, {customMerge})

const de = require('./de.json');
const es = require('./es.json');
const fr = require('./fr.json');
const it = require('./it.json');
const ko = require('./ko.json');
const sv = require('./sv.json');
const pl = require('./pl.json');
const pt = require('./pt.json');
const en = require('./en.json');
const messages = {
    "en": en,
    "ko": ko,
    "it": it,
    "fr": fr,
    "es": es,
    "de": de,
    "sv": sv,
    "pl": pl,
    "pt": pt
}


const createI18n = (options) => {
    const {locale = 'en', runtimeConfig = {}} = options || {};
    const allMessages = merge.all([messages, (runtimeConfig?.i18n || {})], {customMerge});
    const i18n = new I18n({
        messages: allMessages,
        locale,
        fallbackLocale: {
            'default': runtimeConfig?.defaultLocale || 'en'
        }
    });
    i18n.setMessages(allMessages);
    return i18n;
}

const I18nContext = React.createContext(createI18n({}));
const I18nProvider = I18nContext.Provider
const I18nConsumer = I18nContext.Consumer
const locales = require('./locales.json')
if (typeof window !== "undefined") {
   window.I18n = window.I18n || createI18n({})
}
module.exports = {
    default: I18n,
    createI18n,
    I18nProvider,
    I18nContext,
    I18nConsumer,
    messages,
    locales
}
