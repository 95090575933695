const copyBadgeBlock = function ($elements:NodeListOf<Element>, opt = {}) {
    if (!$elements) {
        return;
    }
    if ($elements.constructor.name !== "NodeList" && !Array.isArray($elements)) {
        $elements = [$elements as any] as any
    }
    const options = {
        // base content selector that is searched for snippets
        contentSelector: "body",
        // Delay in ms used for `setTimeout` before badging is applied
        // Use if you need to time highlighting and badge application
        // since the badges need to be applied afterwards.
        // 0 - direct execution (ie. you handle timing
        loadDelay: 0,
        copyIconContent: "",
        // function called before code is placed on clipboard
        // Passed in text and returns back text function(text, codeElement) { return text; }
        onBeforeCodeCopied: null,
        ...opt
    };
    
    function addCodeBadge() {
        for (let index = 0; index < $elements.length; index++) {
            const el = $elements[index];
            if (el.classList.contains('nocopyable') || el.querySelector("copy-badge")) {
                continue; // already exists
            }
            
            const $copyBadge = document.createElement("copy-badge");
            if (el.shadowRoot) {
                el.shadowRoot.appendChild($copyBadge);
            } else {
                el.appendChild($copyBadge);
            }
            el
            if (options.copyIconContent) {
                $copyBadge.setAttribute("content", options.copyIconContent)
            }
        }
    }

    if (options.loadDelay) {
        setTimeout(addCodeBadge, options.loadDelay);
    } else {
        addCodeBadge();
    }
}
export default copyBadgeBlock;