import React from 'react';

//@ts-ignore
if (React.createElement && !React.createElement.isPatched) {
    const originalCreateElement = React.createElement;
    React.createElement = (function (){
        const args = Array.from(arguments);
        const type = args.shift();
        //@ts-ignore
        const overrides = typeof window !== "undefined" ? window.ComponentOverrides || {}:{};
        const displayName = typeof type === 'function' ? type.displayName || type.name || 'Unknown' : type;
        if (overrides[displayName]) {
            console.log("using overriden component for " +displayName)
            //@ts-ignore
            return originalCreateElement.apply(this,[overrides[displayName], ...args]);
        } else {
            return originalCreateElement.apply(this, arguments);
        }
    }) as any;
    //@ts-ignore
    React.createElement.isPatched = true;
}


export default React; 

