import renderVersionSelector from './version-selector.html'
import registerComponent from "../register";
import {ContextBinding} from '@ornery/web-components';
import Router from "next/router";
import DataManager from "../../utils/dataManager";

const versionItem = (link)=> `<mwc-list-item
    class="version-selector-menu-item"
    value="${link.title}"
    href="${link.containedTopicHref || link.href}"
    ${link.selected ? "selected activated" : ""}
    onclick="handleItemClick">${link.versionPrefix}${link.title}</mwc-list-item>`;

/**
 A styled dropdown that uses the Material Web Components 
 Button (mwc-button) and Menu (mwc-menu and mwc-list-item) elements.
 requires the DataManager package from @ornery/web-components and the 
 Router from next/router.

 ### HTML Usage
 ```html
 <version-selector data-items="[...]"></version-selector>
 ```
 ### React Component
 ```jsx
 <version-selector data-items={JSON.stringify([...props.items])} />
 ```

 ### CSS default values
 ```css
 :root {
    --versionSelectorButtonHorizontalPadding: 0.5rem;
    --versionSelectorButtonTextTransform: none;
    --versionSelectorMenuItemHeight: 2rem;
    --versionSelectorThemePrimary: inherit;
    --versionSelectorThemeOnPrimary: inherit;
    --versionSelectorThemePrimarySelected: #fff;
    --versionSelectorThemeOnPrimarySelected: #777;
    --versionSelectorButtonIconColor: inherit;
    --versionSelectorButtonColor: inherit;
    --versionSelectorButtonBackgroundColor: inherit;
    --versionSelectorButtonWhiteSpace: nowrap;
    --versionSelectorButtonMargin: 0.5rem;
    --versionSelectorButtonDisplayNoVersions: none;
    --versionSelectorMenuColor: inherit;
    --versionSelectorMenuBackgroundColor: inherit; 
    --versionSelectorMenuWhiteSpace: nowrap;
}
 ```
 
 * @module PortalVersionSelector
 * @extends {HTMLElement} uses the Material Web Components Button (mwc-button) and Menu (mwc-menu and mwc-menu-item) elements.
 * @element version-selector
 * 
 * @description A dropdown list of all the versions available for a given site-section.
 * 
 * @attr {Array<{INode}>} [data-items=null] - The array list of alternate versions for the current page..
 * Automatically provided to DataManager as part of the next.js runtime pageData as "pageData.versions".
 * @attr {Array<{INode}>} [items=null] - Alias for the data-items attribute. 
 * 
 * @fires version-selected
 * 
 * @cssprop --versionSelectorButtonHorizontalPadding - Overrides the horizontal padding for the mwc-button.
 * @cssprop --versionSelectorButtonTextTransform - Overrides the text-transform property for the mwc-button.
 * @cssprop --versionSelectorMenuItemHeight - Overrides the mwc-list-item height.
 * @cssprop --versionSelectorThemePrimary - Overrides the --mdc-theme-primary css variable for this component.
 * @cssprop --versionSelectorThemeOnPrimary - Overrides the --mdc-theme-on-primary css variable for this component.
 * @cssprop --versionSelectorThemePrimarySelected - Overrides the --mdc-theme-primary css variable for mwc-list-item when selected
 * @cssprop --versionSelectorThemeOnPrimarySelected - Overrides the --mdc-theme-on-primary css variable for mwc-list-item when selected
 * @cssprop --versionSelectorButtonIconColor - Overrides the color property for the mwc-icon.
 * @cssprop --versionSelectorButtonColor - Overrides the color property for the mwc-button.
 * @cssprop --versionSelectorButtonBackgroundColor - Overrides the background-color property for the mwc-button.
 * @cssprop --versionSelectorButtonWhiteSpace - Overrides the white-space property for the mwc-button.
 * @cssprop --versionSelectorButtonMargin - Overrides the margin property for the mwc-button.
 * @cssprop --versionSelectorButtonDisplayNoVersions - Overrides the display property for the mwc-button when there are no versions.
 * @cssprop --versionSelectorMenuColor - Overrides the color property for the mwc-menu
 * @cssprop --versionSelectorMenuBackgroundColor - Overrides the background-color property for the mwc-menu
 * @cssprop --versionSelectorMenuWhiteSpace - Overrides the white-space property for the mwc-menu
 */
export default class PortalVersionSelector extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({ mode: 'open' })
    }
    private versions = [];
    private listener: any;
    
    connectedCallback() {
        this.listener = DataManager.subscribeTo(['pageData'], ()=> {
            this.render()
        })
    }
    
    disconnectedCallback() {
        this.listener && this.listener.destroy();
    }

    getMenu(){
        return this.shadowRoot.querySelector('.version-selector-menu') || this;
    }
    getButton() {
        return this.shadowRoot.querySelector('.version-selector-button') || this;
    }
    
    handleItemClick(evt){
        const href = evt.target.getAttribute('href');
        Router.push(href, href, {scroll: true, shallow: false});
        this.dispatchEvent(new CustomEvent('version-selected', {detail: evt.target.getAttribute('href')}))
        this.setButtonText(this.versionPrefix + evt.target.getAttribute('value'))
    }
    
    get versionPrefix() {
        return this.getAttribute('prefix') || "v ";
    }
    get icon() {
        return this.getAttribute('icon') || "arrow_drop_down";
    }
    get variant() {
        return this.getAttribute('variant') || "raised";
    }
    get selected() {
        return this.getAttribute('selected') || this.getButton().getAttribute('label') || "";
    }
    get versionList():string{
        const items = this.versions as any;
        const versionPrefix = this.versionPrefix;
        return (items).map((link)=> versionItem({
            versionPrefix,
            ...link
        })).join('')
    }
    setButtonText(value) {
        this.getButton().setAttribute('label', value);
    }
    render(){
        const pageData = DataManager.get('pageData') || {};
        const {versions = []} = pageData.content || {};
        if (versions.length > 1) {
            this.versions = versions.map((v)=> {
                v.selected = window.location.href.indexOf(v.href) > -1;
                if (v.href && !v.href.startsWith('/')) {
                    v.href = "/" + v.href
                }
                if (v.containedTopicHref && !v.containedTopicHref.startsWith('/')) {
                    v.containedTopicHref = "/" + v.containedTopicHref
                }
                return v;
            });
            renderVersionSelector(this).connect();
            const menu = this.getMenu() as any;
            const button = this.getButton() as any;
            menu.anchor = button;
            button.addEventListener('click', ()=>{
                menu.open = true;
            })
            const items = Array.from(this.getMenu().querySelectorAll('mwc-list-item'));
            if (items.length > 1) {
                this.getButton().classList.remove('no-versions');
                const selectedItem = items.find(c => c.hasAttribute('selected')) || items[0];
                this.setButtonText(this.versionPrefix + selectedItem.getAttribute('value'))
            } else {
                this.getButton().classList.add('no-versions');
            }
        } else {
            this.getButton().classList.add('no-versions');
        }
    }
}

registerComponent('version-selector', ContextBinding(PortalVersionSelector))
