import ReactGA from "react-ga";
import { datadogRum } from "@datadog/browser-rum";

const initAnalytics = (config:any = {}) => {
    if (config.analytics && typeof window !== 'undefined'){
        if (config.analytics.google || (config.env && config.env.GA_TRACKING_ID)) {
            const GA_TRACKING_ID = (config.env && config.env.GA_TRACKING_ID) || config.analytics.google;
            if (typeof GA_TRACKING_ID === "string") {
                console.debug("initializing google analytics with ID:", config.analytics.google);
                ReactGA.initialize(GA_TRACKING_ID);
                ReactGA.pageview(window.location.pathname + window.location.search);
                //@ts-ignore
                window.GAInitialized = true;
            }
        }
        if (config.analytics.gtm || (config.env && config.env.GTM_TRACKING_ID)) { 
            const GTM_TRACKING_ID = (config.env && config.env.GTM_TRACKING_ID) || config.analytics.gtm;
            if (typeof GTM_TRACKING_ID === "string") {
                console.debug("initializing google tag manager with ID:", GTM_TRACKING_ID);
                const dataLayer = config.analytics.googledl || "dataLayer";
                let dl = dataLayer != "dataLayer" ? "&l=" + dataLayer : "";
                //@ts-ignore
                window[dataLayer] = window[dataLayer] || config.analytics[dataLayer] || [];
                //@ts-ignore
                window[dataLayer].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                let firstScript = document.getElementsByTagName("script")[0];
                let scriptParent = firstScript.parentNode;
                const gtmScript = document.createElement("script");
                gtmScript.async = true;
                gtmScript.nonce = config.nonce;
                gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_TRACKING_ID}${dl}`;
                scriptParent.insertBefore(gtmScript, firstScript);
                //@ts-ignore
                window.GTMInitialized = true;
            }
        }
        if (config.analytics.datadog) {
            if (typeof config.analytics.datadog.applicationId === "string") {
                console.debug("initializing datadog analytics with options:", config.analytics.datadog);
                datadogRum.init(config.analytics.datadog as any);
                //@ts-ignore
                window.DDInitialized = true;
            }
        }
    }
};

export default initAnalytics;
