import renderPrintContent from './print-content.html'
import registerComponent, {upgradeWebComponents} from "../register";
import {ContextBinding} from '@ornery/web-components';
import path from "path";
import {toSearch} from "@jorsek/content-portal-utils";
import Router from "next/router";
import {scrollToTop} from "../../utils/ScrollUtil";

/**
 A styled dropdown that uses the Material Web Components
 Button (mwc-button) and Menu (mwc-menu and mwc-list-item) elements.
 requires the DataManager package from @ornery/web-components.

 ### HTML Usage
 ```html
 <print-content has-children="true"></print-content>
 ```
 ### React Component
 ```jsx
 <print-content has-children={true}/>
 ```

 ### CSS default values
 ```css
 :root {
    --printContentIconFontSize: 24px;
    --printContentIconFontFamily: Material Icons;
    --printContentMenuItemHeight: 2rem;
    --printContentThemePrimary: inherit;
    --printContentThemeOnPrimary: inherit;
    --printContentButtonColor: inherit;
    --printContentButtonBackgroundColor: inherit;
    --printContentButtonWhiteSpace: nowrap;
    --printContentMenuColor: inherit;
    --printContentMenuBackgroundColor: inherit; 
    --printContentMenuWhiteSpace: nowrap;
}
 ```

 * @module PortalprintContent
 * @extends {HTMLElement} uses the Material Web Components Button (mwc-button) and Menu (mwc-menu and mwc-menu-item) elements.
 * @element print-content
 *
 * @description A printing component that handles crtl+p, and ctrl+shift+p on top o provides a dropdown for printing content pages.
 *
 * @attr {Boolean} [has-children=false] - flag to determine whether the current page has children pages that can be printed in tandem.
 * @attr {Boolean} [prettify-code=true] - flag to determine whether to process content through prettier-code for <code> elements.
 * @attr {Boolean} [prettify-pre=true] - flag to determine whether to process content through prettier-code for <pre> elements.
 * @attr {String} [prettify-code-lang="bash"] - language to use for syntax highlighting.
 *
 * @cssprop --printContentIconFontSize - Overrides the mwc-icon-button font-size.
 * @cssprop --printContentIconFontFamily - Overrides the mwc-icon-button font-family.
 * @cssprop --printContentMenuItemHeight - Overrides the mwc-list-item height.
 * @cssprop --printContentThemePrimary - Overrides the --mdc-theme-primary css variable for this component.
 * @cssprop --printContentThemeOnPrimary - Overrides the --mdc-theme-on-primary css variable for this component.
 * @cssprop --printContentButtonColor - Overrides the color property for the mwc-button.
 * @cssprop --printContentButtonBackgroundColor - Overrides the background-color property for the mwc-button.
 * @cssprop --printContentButtonWhiteSpace - Overrides the white-space property for the mwc-button.
 * @cssprop --printContentMenuColor - Overrides the color property for the mwc-menu
 * @cssprop --printContentMenuBackgroundColor - Overrides the background-color property for the mwc-menu
 * @cssprop --printContentMenuWhiteSpace - Overrides the white-space property for the mwc-menu
 */
export default class PortalPrintContent extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({mode: 'open'})
    }

    attributeChangedCallback() {
        this.render()
    }

    connectedCallback() {
        this.render()
        document.addEventListener('keydown', this.handleKeydown)
    }

    disconnectedCallback() {
        document.removeEventListener('keydown', this.handleKeydown)
    }

    getMenu(): any {
        return this.shadowRoot.querySelector('.print-content-menu') || this;
    }

    getButton(): any {
        return this.shadowRoot.querySelector('.print-content-button') || this;
    }

    async handleButtonClick(evt) {
        if (!this.hasChildren) {
            return await this.printContent()
        } else {
            if (!evt.shiftKey) {
                this.getMenu().open = true;
            } else {
                return await this.printContentFull()
            }
        }
    }

    async printContent() {
        await this.triggerPrint();
    }

    async printContentFull() {
        await this.triggerPrint(true);
    }

    get hasChildren() {
        return this.getAttribute('has-children') === "true"
    }

    handleKeydown = async (evt) => {
        if ((evt.ctrlKey || evt.metaKey) && evt.keyCode === 80) {
            evt.preventDefault();
            evt.stopPropagation();
            return await this.handleButtonClick(evt);
        }
    }

    async fetchApiContent(contentPath, options = null) {
        return await fetch(path.join('/api/content', contentPath, options ? toSearch(options) : ''))
        .then(response => response.json())
        .catch((err) => console.log(err.message))
    }

    outputClasses = () => {
        const val = this.getAttribute('output-classes');
        if (val) {
            try {
                return JSON.parse(val);
            } catch (ex) {
            }
        }
        return val || {};
    }

    async getPrintContent(full: boolean): Promise<string> {
        const printParams = {
            "include-metadata": true,
            "print": full,
            locale: Router.locale
        };
        let printableContent = (await this.fetchApiContent(this.getAttribute('content-path'), printParams)).content
        return printableContent
    }

    async triggerPrint(full = false) {
        const scrollFinished = scrollToTop()
        this.getMenu().open = false;
        const renderRoot = document.getElementById('printing-root');
        let printableContent = await this.getPrintContent(full)

        let printObject = renderRoot;
        printObject = document.createElement('render-html')
        printObject.setAttribute('shadow', 'false')
        printObject.setAttribute('html', printableContent)
        printObject.classList.add('printing-full')
        if (renderRoot) {
            renderRoot.classList.add('printing-full-hide')
            renderRoot.parentNode.appendChild(printObject)
        }        
        upgradeWebComponents(printObject, this.outputClasses())
        await Promise.all(Array.from(printObject.querySelectorAll('img') || []).map((img) => {
            return new Promise((resolve) => {
                const finish = (evt) => {
                    img.removeEventListener('load', finish)
                    img.removeEventListener('error', finish)
                    resolve(evt)
                }
                img.addEventListener('load', finish)
                img.addEventListener('error', finish)
            })
        })).catch((err) => console.log(err.message))

        let iframeEl = printObject.querySelectorAll("iframe");
        if (iframeEl.length > 0) {
            iframeEl.forEach(frame => {
                const parent = frame.parentNode;
                const wrapper = document.createElement('div');
                wrapper.classList.add("video-wrapper");
                const videoLink = document.createElement('a');
                videoLink.setAttribute("href", frame.getAttribute('src'));
                videoLink.setAttribute("target", "_blank");
                videoLink.classList.add("video-link");
                const closestArticle = frame.closest("article");
                const articleTitle = closestArticle.querySelector(".title");
                videoLink.append(articleTitle.innerHTML);
                const placeholder = document.createElement('span');
                placeholder.classList.add("material-icons");
                placeholder.classList.add("ondemand_video");
                placeholder.innerHTML = "ondemand_video";
                frame.style.display = "none";
                wrapper.appendChild(videoLink);
                wrapper.appendChild(placeholder);
                parent.appendChild(wrapper);
            });
        }
        await scrollFinished
        setTimeout(() => {
            window.print();
            this.cleanup();
        })
    }

    cleanup() {
        const renderRoot = document.getElementById('printing-root');
        if (renderRoot) {
            renderRoot.classList.remove('printing-full-hide')
            renderRoot.parentNode.removeChild(renderRoot.parentNode.children[renderRoot.parentNode.children.length - 1])
        }
    }

    get printLabel() {
        return I18n?.get("label.print")
    };

    get printSubLabel() {
        return I18n?.get("label.print-sub")
    };

    render() {
        renderPrintContent(this).connect();
        this.getMenu().anchor = this.getButton();
        this.getMenu().corner = "BOTTOM_RIGHT";
        this.getMenu().menuCorner = "END";
    }
}

registerComponent('print-content', ContextBinding(PortalPrintContent))
